<template>
  <v-container>
    <v-row>
      <v-col v-if="$vuetify.breakpoint.mdAndUp"></v-col>
      <v-col md="8" sm="12">
        <v-toolbar>
          <v-btn icon @click="curPage--" :disabled="curPage == 1">
            <v-icon>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>
            Reviews &amp; Quotes
            <br />
            <span class="subtitle-2">{{ curPage }} of {{ pageCount }}</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="curPage++" :disabled="curPage == pageCount">
            <v-icon>
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </v-toolbar>

        <v-data-iterator
          :items="rqList"
          :items-per-page="1"
          :page="curPage"
          hide-default-footer
        >
          <template #default="{items}">
            <v-card
              v-for="{ id, text, attribution } in items"
              :key="id"
              outlined
              class="my-2"
            >
              <v-container>
                <v-row fill-height>
                  <v-card-text
                    v-html="text"
                    class="text-left blockquote"
                  ></v-card-text>
                  <v-card-subtitle
                    v-html="attribution"
                    class="text-right"
                  ></v-card-subtitle>
                </v-row>
              </v-container>
            </v-card>
          </template>
        </v-data-iterator>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.mdAndUp"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import marked from "marked";
import uniqid from "uniqid";
import { mapState } from "vuex";

const renderer = new marked.Renderer();

renderer.link = function(href, title, text) {
  return (
    '<a target="_blank" href="' +
    href +
    '" title="' +
    title +
    '">' +
    text +
    "</a>"
  );
};

export default {
  data: () => ({
    curPage: 1,
  }),

  computed: {
    ...mapState(["reviewsQuotes"]),

    rqList() {
      const data = this.reviewsQuotes.map((item) => {
        return {
          text: marked(item.text, { renderer: renderer }),
          attribution: marked(item.attribution, { renderer: renderer }),
          id: uniqid(),
        };
      });

      return data;
    },

    pageCount() {
      return this.rqList.length;
    },
  },
};
</script>
